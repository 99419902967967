<style lang="scss">
.kontakte-all {
  padding: 0 15px;
  h1 {
    @media (max-width: $width-lg) {
      text-align: center;
    }
  }

  .kontakt-box {
    text-align: center;
    border-right: 5px solid $blue-light-v2;
    padding: 20px 0px;
    @media (max-width: $width-lg) {
      border-right: 0;
      &:after {
        content: '';
        display: block;
        width: 100px;
        height: 5px;
        background: $blue-light-v2;
        margin: 40px auto 0 auto;
      }
    }
    &:last-child {
      border-right: 0;
      @media (max-width: $width-md) {
        &:after {
          display: none;
        }
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    span {
      @media (max-width: 1350px) and (min-width: 1200px) {
        display: block;
      }
    }
    a {
      &:hover {
        color: $blue !important;
      }
    }
  }
  .top-header {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
  }

  .kontakt-box-outer {
    margin-top: 120px;
    h3 {
      margin-top: 30px;
      border-top: 2px solid $red;
      padding: 45px 0 35px 0px;
      text-align: left;
    }
    .box-inner {
      text-align: center;
      padding: 15px;
      margin-bottom: 30px;

      .img-box {
        width: 200px;
        height: 200px;
        display: block;
        margin: 10px auto 30px auto;
        border-radius: 50%;
        background: #fff;
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center 10px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
      }
      .img-position-bottom {
        background-position: center bottom;
      }
      .img-harald-kaefer {
        background-size: 130%;
        background-position: top left;
      }
      .elke-hartweger {
        background-size: 100%;
        background-position: -10px top;
      }
      .location {
        font-size: 120%;
        display: none;
        strong {
          display: block;
          &:after {
            content: '';
            display: block;
            height: 30px;
            width: 3px;
            background: $red;
            margin: 15px auto;
          }
        }
      }
      .name {
        font-size: 120%;
        font-family: $KleineSans-Bold;
      }
      .posi {
        margin-top: 10px;
      }
      a {
        display: block;
        padding: 3px 0;
        margin: 2px 0;
        &:hover {
          color: $blue !important;
        }
      }
    }
  }
  .icon-box {
    text-align: center;
    height: 100px;
    width: 100px;
    margin: 15px auto;
    //border-radius: 50%;
    background: $blue-darker;
    span {
      display: block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      font-size: 250%;
      color: #fff;
    }
  }
}
</style>

<template>
  <div class="kontakte-all content">
    <div class="container">
      <h1>Wir sind für Sie da!</h1>

      <div class="row justify-content-center">
        <div class="col-lg-6 margin-tb-s align-self-center">
          <h2>Kompetente Werbeberatung im Print- und Digital-Bereich ist unsere Stärke, professionelle und erfolgreiche Kampagnen unser Angebot an Sie.</h2>
        </div>
        <div class="col-lg-6 margin-tb-s align-self-center">
          <h2>Gibt es aktuell in Ihrem Unternehmen Bedarf für crossmediale Werbelösungen? <span class="breaker"></span>Unser Team unterstützt Sie dabei gerne!</h2>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-4 align-self-center">
          <div class="icon-box"><span class="material-icons">person</span></div>
        </div>
        <div class="col-lg-4 col-4 align-self-center">
          <div class="icon-box"><span class="material-icons">call</span></div>
        </div>
        <div class="col-lg-4 col-4 align-self-center">
          <div class="icon-box"><span class="material-icons">mail</span></div>
        </div>
      </div>

      <div class="row margin-tb-m">
        <div class="col-lg-4 col-md-12 kontakt-box">
          <h3>Werbemarkt<br />Steiermark</h3>
          <p>
            Gadollaplatz 1<br />
            8010 Graz
          </p>
          <p><span>Tel.: </span><a href="tel:0316/875 33 02">0316/875 33 02</a></p>
          <p><span>E-Mail: </span><a href="mailto:meinewerbung@kleinezeitung.at">meinewerbung@kleinezeitung.at</a></p>
        </div>
        <div class="col-lg-4 col-md-6 kontakt-box">
          <h3>Werbemarkt<br />Kärnten</h3>
          <p>
            Hasnerstraße 2<br />
            9020 Klagenfurt
          </p>
          <p><span>Tel.:</span> <a href="tel:0463/58 00 286">0463/58 00 286</a></p>
          <p><span>E-Mail:</span> <a href="mailto:meinewerbung@kleinezeitung.at">meinewerbung@kleinezeitung.at</a></p>
        </div>
        <div class="col-lg-4 col-md-6 kontakt-box">
          <h3>Werbemarkt<br />National</h3>
          <p>
            Lobkowitzplatz 1<br />
            1010 Wien
          </p>
          <p><span>Tel.:</span> <a href="tel:01/512 16 18">01/512 16 18</a></p>
          <p><span>E-Mail:</span> <a href="mailto:meinewerbung@kleinezeitung.at">meinewerbung@kleinezeitung.at</a></p>
        </div>
      </div>

      <div class="row justify-content-center align-self-center bg-blue-light highlight-box ">
        <div class="col-sm-6 d-flex align-self-center">
          <p>Direkt, schnell und unkompliziert: <span class="breaker"></span>Nutzen Sie unser Kontakt-Formular!</p>
        </div>
        <div class="col-sm-6 d-flex align-self-center justify-content-end">
          <div class="btn-std btn-bg-blue-dark">
            <router-link to="kontakt-formular">Wir freuen uns auf Sie</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container kontakt-box-outer">
      <!--<div class="whitespace-line whitespace-bg-blue margin-tb-m"></div>-->
      <!-- -->
      <h2 class="margin-b-s">Ihre Werbeberaterinnen und Werbeberater in der Region</h2>

      <h3>Regionalverkauf Steiermark West</h3>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da in <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Robert-Nebel.png')})` }"></div>
            <div class="name">Robert Nebel</div>
            <div class="posi">Leitung</div>
            <a href="mailto:robert.nebel@kleinezeitung.at">robert.nebel@kleinezeitung.at</a>
            <a href="tel:43664808753323">+43 (0) 664 808 75 3323</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Nadine-Probst.png')})` }"></div>
            <div class="name">Nadine Propst</div>
            <div class="posi">Digitale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:nadine.propst@kleinezeitung.at">nadine.propst@kleinezeitung.at</a>
            <a href="tel:+436641533285">+43 (0) 664 15 33 285</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Manuela-Hobelleitner.png')})` }"></div>
            <div class="name">Manuela Hobelleitner</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:manuela.hobelleitner@kleinezeitung.at">manuela.hobelleitner@kleinezeitung.at</a>
            <a href="tel:+4369912345295">+43 (0) 699 12 34 5295</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Alexander-Lienhart.png')})` }"></div>
            <div class="name">Alexander Lienhart</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:alexander.lienhart@kleinezeitung.at">alexander.lienhart@kleinezeitung.at</a>
            <a href="tel:+436644188128">+43 (0) 664 41 88 128</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Melita-Lipovec.png')})` }"></div>
            <div class="name">Melita Lipovec</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:melita.lipovec@kleinezeitung.at">melita.lipovec@kleinezeitung.at</a>
            <a href="tel:+436645480787">+43 (0) 664 54 80 787</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Sandra-Melin.png')})` }"></div>
            <div class="name">Sandra Melin</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:sandra.melin@kleinezeitung.at">sandra.melin@kleinezeitung.at</a>
            <a href="tel:+436642321325">+43 (0) 664 23 21 325</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Matthias-Pagger.png')})` }"></div>
            <div class="name">Matthias Pagger</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:matthias.pagger@kleinezeitung.at">matthias.pagger@kleinezeitung.at</a>
            <a href="tel:+4369915240020">+43 (0) 699 15 24 0020</a>
          </div>
        </div>
      </div>

      <h3>Regionalverkauf Steiermark Ost</h3>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box img-position-bottom img-harald-kaefer" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK-Harald-Kaefer.png')})` }"></div>
            <div class="name">Harald Käfer</div>
            <div class="posi">Leitung</div>
            <a href="mailto:harald.kaefer@kleinezeitung.at">harald.kaefer@kleinezeitung.at</a>
            <a href="tel:+4369915121620">+43 (0) 699 15 12 1620</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Juergen-Haluzan.png')})` }"></div>
            <div class="name">Jürgen Haluzan</div>
            <div class="posi">Digitale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:juergen.haluzan@kleinezeitung.at">juergen.haluzan@kleinezeitung.at</a>
            <a href="tel:+436644037060">+43 (0) 664 40 37 060</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Christoph-Burboeck.png')})` }"></div>
            <div class="name">Christoph Burböck</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:christoph.burboeck@kleinezeitung.at">christoph.burboeck@kleinezeitung.at</a>
            <a href="tel:+436648144626">+43 (0) 664 81 44 626</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Goran-Gjergjek.png')})` }"></div>
            <div class="name">Goran Gjergjek</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:goran.gjergjek@kleinezeitung.at">goran.gjergjek@kleinezeitung.at</a>
            <a href="tel:+4369918753391">+43 (0) 699 18 75 3391</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Karl-Gutmann.png')})` }"></div>
            <div class="name">Karl Gutmann</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:karl.gutmann@kleinezeitung.at">karl.gutmann@kleinezeitung.at</a>
            <a href="tel:+436644453007">+43 (0) 664 44 53 007</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Hans-Christian-Haller.png')})` }"></div>
            <div class="name">Hans-Christian Haller</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:hans-christian.haller@kleinezeitung.at">hans-christian.haller@kleinezeitung.at</a>
            <a href="tel:+4369918753337">+43 (0) 699 18 75 3375</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Philipp-Jocham.png')})` }"></div>
            <div class="name">Philipp Jocham</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:philipp.jocham@kleinezeitung.at">philipp.jocham@kleinezeitung.at</a>
            <a href="tel:+43664808753364">+43 (0) 664 808 75 3364</a>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Christopher-Smretschnig.png')})` }"></div>
            <div class="name">Christopher Smretschnig</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:christopher.smretschnig@kleinezeitung.at">christopher.smretschnig@kleinezeitung.at</a>
            <a href="tel:+4369918753362">+43 (0) 699 18 75 3362</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XXX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Michael-Thewanger.png')})` }"></div>
            <div class="name">Michael Thewanger</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:michael.thewanger@kleinezeitung.at">michael.thewanger@kleinezeitung.at</a>
            <a href="tel:+43664808757002">+43 (0) 664 808 75 7002</a>
          </div>
        </div>
      </div>

      <h3>Regionalverkauf Kärnten</h3>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Christoph-Frohnwieser.png')})` }"></div>
            <div class="name">Christoph Frohnwieser</div>
            <div class="posi">Leitung</div>
            <a href="mailto:christoph.frohnwieser@kleinezeitung.at">christoph.frohnwieser@kleinezeitung.at</a>
            <a href="tel:+4369913555613">+43 (0) 699 13 55 5613 </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Caroline-Schoeffmann.png')})` }"></div>
            <div class="name">Caroline Schöffmann</div>
            <div class="posi">Digitale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:caroline.schoeffmann@kleinezeitung.at">caroline.schoeffmann@kleinezeitung.at</a>
            <a href="tel:+436606944738">+43 (0) 660 69 44 738 </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Siegfried-Czapek.png')})` }"></div>
            <div class="name">Siegfried Czapek</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:Siegfried.Czapek@kleinezeitung.at">siegfried.czapek@kleinezeitung.at</a>
            <a href="tel:+436641047411">+43 (0) 664 10 47 411</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box elke-hartweger" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Elke-Hartweger-v2.png')})` }"></div>
            <div class="name">Elke Hartweger</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:elke.hartweger@kleinezeitung.at">elke.hartweger@kleinezeitung.at</a>
            <a href="tel:+4369915800249">+43 (0) 699 15 80 0249</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Renato-Raatz.png')})` }"></div>
            <div class="name">Renato Raatz</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:renato.raatz@kleinezeitung.at">renato.raatz@kleinezeitung.at</a>
            <a href="tel:+436641525575">+43 (0) 664 15 25 575</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Manuel-Ressi.png')})` }"></div>
            <div class="name">Manuel Ressi</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:manuel.ressi@kleinezeitung.at">manuel.ressi@kleinezeitung.at</a>
            <a href="tel:+4369915091882">+43 (0) 699 15 09 1882</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Martin-Schaller-Fercher.png')})` }"></div>
            <div class="name">Martin Schaller-Fercher</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:martin.schaller@kleinezeitung.at">martin.schaller@kleinezeitung.at</a>
            <a href="tel:+4369915800158">+43 699 15 80 0158</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Carina-Schloegl-Modritsch.png')})` }"></div>
            <div class="name">Carina Schlögl-Modritsch</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:carina.schloeglmodritsch@kleinezeitung.at">carina.schloeglmodritsch@kleinezeitung.at</a>
            <a href="tel:+4369915800433">+43 699 15 80 0433 </a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Ivana-Schneeberger.png')})` }"></div>
            <div class="name">Ivana Schneeberger</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:ivana.schneeberger@kleinezeitung.at">ivana.schneeberger@kleinezeitung.at</a>
            <a href="tel:+4369915800284">+43 (0) 699 15 80 0284</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Marija-Stipic-Raatz.png')})` }"></div>
            <div class="name">Marija Stipic-Raatz</div>
            <div class="posi">Regionale Verkaufs- &amp; Kommunikationslösung</div>
            <a href="mailto:marija.stipic-raatz@kleinezeitung.at">marija.stipic-raatz@kleinezeitung.at</a>
            <a href="tel:+4369915800466">+43 (0) 699 15 80 0466</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container kontakt-box-outer">
      <h2 class="margin-b-s">Ihre Werbeberaterinnen und Werbeberater National</h2>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Andreas-Janzek.png')})` }"></div>
            <div class="name">Andreas Janzek</div>
            <div class="posi">Leitung</div>
            <a href="mailto:andreas.janzek@kleinezeitung.at">andreas.janzek@kleinezeitung.at</a>
            <a href="tel:+4369913589636">+43 (0) 699 13 58 9636</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Eva-Maria-Gruber.png')})` }"></div>
            <div class="name">Eva Gruber</div>
            <div class="posi">Stellv. Leitung</div>
            <a href="mailto:eva.gruber@kleinezeitung.at">eva.gruber@kleinezeitung.at</a>
            <a href="tel:+4369915121600">+43 (0) 699 15 12 1600</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Richard-Brixel.png')})` }"></div>
            <div class="name">Richard Brixel</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:richard.brixel@kleinezeitung.at">richard.brixel@kleinezeitung.at</a>
            <a href="tel:+43664808753369">+43 (0) 664 808 75 3369</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Doris-Baumgartner.png')})` }"></div>
            <div class="name">Doris Baumgartner</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:doris.baumgartner@kleinezeitung.at">doris.baumgartner@kleinezeitung.at</a>
            <a href="tel:+4369915121673">+43 (0) 699 15 12 1673</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Thomas-Kohlweiss.png')})` }"></div>
            <div class="name">Thomas Kohlweiss</div>
            <div class="posi">Digital Key Account / Client Service</div>
            <a href="mailto:thomas.kohlweiss@kleinezeitung.at">thomas.kohlweiss@kleinezeitung.at</a>
            <a href="tel:+436601044624">+43 (0) 660 10 44 624</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Heimo-Reschun.png')})` }"></div>
            <div class="name">Heimo Reschun</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:reimo.reschun@kleinezeitung.at">heimo.reschun@kleinezeitung.at</a>
            <a href="tel:+4369915800259">+43 (0) 699 15 80 0259</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Christian-Schneebauer.png')})` }"></div>
            <div class="name">Christian Schneebauer</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:christian.schneebauer@kleinezeitung.at">christian.schneebauer@kleinezeitung.at</a>
            <a href="tel:+436648144600">+43 (0) 664 81 44 600</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container kontakt-box-outer" style="display:none;">
      <h3>Nationaler Verkauf</h3>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Andreas-Janzek.png')})` }"></div>
            <div class="name">Andreas Janzek</div>
            <div class="posi">Leitung</div>
            <a href="mailto:andreas.janzek@kleinezeitung.at">andreas.janzek@kleinezeitung.at</a>
            <a href="tel:+4369913589636">+43 (0) 699 13 58 9636</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Eva-Maria-Gruber.png')})` }"></div>
            <div class="name">Eva Gruber</div>
            <div class="posi">Stellv. Leitung</div>
            <a href="mailto:eva.gruber@kleinezeitung.at">eva.gruber@kleinezeitung.at</a>
            <a href="tel:+4369915121600">+43 (0) 699 15 12 1600</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Richard-Brixel.png')})` }"></div>
            <div class="name">Richard Brixel</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:richard.brixel@kleinezeitung.at">richard.brixel@kleinezeitung.at</a>
            <a href="tel:+43664808753369">+43 (0) 664 808 75 3369</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Doris-Baumgartner.png')})` }"></div>
            <div class="name">Doris Baumgartner</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:doris.baumgartner@kleinezeitung.at">doris.baumgartner@kleinezeitung.at</a>
            <a href="tel:+4369915121673">+43 (0) 699 15 12 1673</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Thomas-Kohlweiss.png')})` }"></div>
            <div class="name">Thomas Kohlweiss</div>
            <div class="posi">Digital Key Account / Client Service</div>
            <a href="mailto:thomas.kohlweiss@kleinezeitung.at">thomas.kohlweiss@kleinezeitung.at</a>
            <a href="tel:+436601044624">+43 (0) 660 10 44 624</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Heimo-Reschun.png')})` }"></div>
            <div class="name">Heimo Reschun</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:reimo.reschun@kleinezeitung.at">heimo.reschun@kleinezeitung.at</a>
            <a href="tel:+4369915800259">+43 (0) 699 15 80 0259</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>Murtal</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/NAT_Christian-Schneebauer.png')})` }"></div>
            <div class="name">Christian Schneebauer</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:christian.schneebauer@kleinezeitung.at">christian.schneebauer@kleinezeitung.at</a>
            <a href="tel:+436648144600">+43 (0) 664 81 44 600</a>
          </div>
        </div>
      </div>
      <!-- -->

      <h3>KLZ Brand Studio</h3>
      <div class="row justify-content-center">
        <div class="col-lg-4" style="border:1px solid red;">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Pierre-Bechler.png')})` }"></div>
            <div class="name">Nina Prehofer</div>
            <div class="posi">Leitung</div>
            <a href="mailto:nina.prehofer@kleinezeitung.at">nina.prehofer@kleinezeitung.at</a>
            <a href="tel:+43664">+43 (0) 664</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Pierre-Bechler.png')})` }"></div>
            <div class="name">Pierre Bechler</div>
            <div class="posi">Leitung</div>
            <a href="mailto:pierre.bechler@kleinezeitung.at">pierre.bechler@kleinezeitung.at</a>
            <a href="tel:+436648144758">+43 (0) 664 81 44 758</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da in <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Christian-Eder.png')})` }"></div>
            <div class="name">Christian Eder</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:christian.eder@kleinezeitung.at">christian.eder@kleinezeitung.at</a>
            <a href="tel+43664808753376">+43 (0) 664 808 75 3376</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Sandra-Freidl.png')})` }"></div>
            <div class="name">Sandra Freidl</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:sandra.freidl@kleinezeitung.at">sandra.freidl@kleinezeitung.at</a>
            <a href="tel:+43664808753378">+43 (0) 664 808 75 3378</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Jasmin-Johanna-Gaggl.png')})` }"></div>
            <div class="name">Jasmin Johanna Gaggl</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:Jasmin.Gaggl@kleinezeitung.at">jasmin.gaggl@kleinezeitung.at</a>
            <a href="tel:+4369915800264">+43 (0) 699 15 80 0264</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Robert-Hirschmugl.png')})` }"></div>
            <div class="name">Robert Hirschmugl</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:robert.hirschmugl@kleinezeitung.at">robert.hirschmugl@kleinezeitung.at</a>
            <a href="tel:+4369918753343">+43 (0) 699 18 75 3343</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Anna-Horn.png')})` }"></div>
            <div class="name">Anna Horn</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:anna.horn@kleinezeitung.at">anna.horn@kleinezeitung.at</a>
            <a href="tel:+434635800207">+43 (0) 463 58 00 207</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Klaus-Krall.png')})` }"></div>
            <div class="name">Klaus Krall</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:klaus.krall@kleinezeitung.at">klaus.krall@kleinezeitung.at</a>
            <a href="tel:+4369915800424">+43 (0) 699 15 80 0424</a>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Andreas-Prueckler.png')})` }"></div>
            <div class="name">Andreas Prückler</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:andreas.prueckler@kleinezeitung.at">andreas.prueckler@kleinezeitung.at</a>
            <a href="tel:+4369918753105">+43 (0) 699 18 75 3105</a>
          </div>
        </div>
        <div class="col-lg-4" style="border:1px solid red;">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/STMK_Andreas-Prueckler.png')})` }"></div>
            <div class="name">Thamer</div>
            <div class="posi">Key Account / Client Service</div>
            <a href="mailto:@kleinezeitung.at">@kleinezeitung.at</a>
            <a href="tel:+43699">+43 (0) </a>
          </div>
        </div>

        <!-- WOHIN ??-->
        <!--
        <div class="col-lg-4">
          <div class="box-inner">
            <div class="location">Für Sie da im <strong>XX</strong></div>
            <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/kontakt/KTN_Jennifer-Valtiner.png')})` }"></div>
            <div class="name">Jennifer Valtiner</div>
            <a href="mailto:jennifer.valtiner@kleinezeitung.at">jennifer.valtiner@kleinezeitung.at</a>
            <a href="tel:+4369915800348">+43 (0) 699 15 80 0348</a>
          </div>
        </div>-->

        <!-- -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Kontakte',
});
</script>
